<template>
  <span>
  {{ formatted }}
</span>
</template>
<script lang="ts" setup>
import type {PropType} from "vue";

const props = defineProps({
  date: {
    type: [Date, String] as PropType<Date | string>,
    required: true
  },
  format: {
    type: String as PropType<Intl.DateTimeFormatOptions['dateStyle']>,
    default: 'short'
  },
  options: {
    type: Object as PropType<Intl.DateTimeFormatOptions>,
    default: () => ({})
  }
});

let locale = ref<string>(((useRequestHeader('accept-language')?.split(',')?.[0]) ?? 'en-GB'));

onMounted(() => locale.value = navigator.language);
const formatted = computed(() => new Intl.DateTimeFormat(locale.value, {
  dateStyle: props.format,
  ...props.options
}).format(typeof props.date === 'string' ? new Date(props.date) : props.date));

</script>
